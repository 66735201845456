import './index.scss';
import { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Modal, Select, notification } from 'antd';

import { getSubscriptionPlans, addSubscriptionPlan, updateSubscriptionPlan, deleteSubscriptionPlan } from '../../services/SubscriptionPlanService';

import { Search } from '../../components/Search';
import { SubscriptionPlanList } from '../../components/SubscriptionPlanList';

import plans from '../../assets/json/plans.json';

const ADD_MODE = 0;
const UPDATE_MODE = 1;

export const SubscriptionPlans = () => {

    const frequencies = [
        {value: 'DAILY', label: 'DAILY'},
        {value: 'WEEKLY', label: 'WEEKLY'},
        {value: 'BI_WEEKLY', label: 'BI_WEEKLY'},
        {value: 'MONTHLY', label: 'MONTHLY'},
        {value: 'BI_MONTHLY', label: 'BI_MONTHLY'},
        {value: 'QUARTERLY', label: 'QUARTERLY'},
        {value: 'SEMI_ANNUAL', label: 'SEMI_ANNUAL'},
        {value: 'ANNUAL', label: 'ANNUAL'},
        {value: 'UNLIMITED', label: 'UNLIMITED'}
    ]

    const [ subscriptionPlanOpen, setSubscriptionPlanOpen] = useState(false);
    const [ deleteOpen, setDeleteOpen ] = useState(false);
    const [ subscriptionPlan, setSelectedSubscriptionPlan ] = useState();
    const [ subscriptionPlans, setSubscriptionPlans ] = useState();

    const [ mode, setMode ] = useState();
    
    const [ form ] = Form.useForm();

    /**
     * Initialize the SubscriptionPlan List
     */
    useEffect(() => {
        fetchSubscriptionPlans();
    }, []);

    /**
     * Fetch the subscriptionPlans from the server
     */
    const fetchSubscriptionPlans = async () => {
        const data = await getSubscriptionPlans();
        setSubscriptionPlans(data);
    }

    /**
     * Opens the Add SubscriptionPlan modal.
     */
    const openAddSubscriptionPlan = () => {
        setMode(ADD_MODE);
        form.resetFields();
        setSubscriptionPlanOpen(true);
    }

    /**
     * Callback for opening the Update SubscriptionPlan Modal.
     * 
     * @param {*} subscriptionPlan 
     */
    const openUpdateSubscriptionPlan = (subscriptionPlan) => {
        setMode(UPDATE_MODE);
        form.setFieldValue('id', subscriptionPlan.id);
        form.setFieldValue('name', subscriptionPlan.name);
        form.setFieldValue('frequency', subscriptionPlan.frequency);
        form.setFieldValue('price', subscriptionPlan.price);
        form.setFieldValue('downloads', subscriptionPlan.downloads);
        // TODO features here
        setSubscriptionPlanOpen(true);
    }

    /**
     * Callback for opening the Delete SubscriptionPlan Modal.
     * 
     * @param {*} subscriptionPlan 
     */
    const openDeleteSubscriptionPlan = (subscriptionPlan) => {
        setSelectedSubscriptionPlan(subscriptionPlan);
        setDeleteOpen(true);
    }

    /**
     * Save the SubscriptionPlan.
     * Save either adds or updates the subscriptionPlan.
     * 
     * @param {*} value 
     */
    const saveSubscriptionPlan = async (value) => {

        const subscriptionPlan = {...value};
        
        try {

            if (mode === ADD_MODE) {

                const response = await addSubscriptionPlan(subscriptionPlan);
                if (!response.error) {
                    notification.success({
                        message: 'Subscription Plan Added', 
                        description: `The subscription plan was added successfully.`
                    });
                    const list = [response, ...subscriptionPlans];
                    setSubscriptionPlans(list);
                } else {
                    notification.error({
                        message: `Adding the Subscription Plan Failed!`, 
                        description: `An error occurred while adding the subscriptionPlan.`
                    });
                }

            } else {

                const response = await updateSubscriptionPlan(subscriptionPlan);
                if (!response.error) {
                    notification.success({
                        message: 'Subscription Plan Updated', 
                        description: `The subscription plan was updated successfully.`
                    });
                    const list = [...subscriptionPlans];
                    const ndx = list.findIndex((subscriptionPlan) => subscriptionPlan.id === response.id);
                    list[ndx] = response;
                    setSubscriptionPlans(list);
                } else {
                    notification.error({
                        message: `Updating the Subscription Plan Failed!`, 
                        description: `An error occurred while updating the subscriptionPlan.`
                    });
                }
            }

            setSubscriptionPlanOpen(false);
            
        } catch (e) {
            notification.error({
                message: `General Failure`, 
                description: `A general error occurred while an operation was ongoing.`
            });
            setSubscriptionPlanOpen(false);
        }
    }

    /**
     * Confirms Deleting the SubscriptionPlan.
     */
    const confirmDeleteSubscriptionPlan = async () => {

        try {

            const response = await deleteSubscriptionPlan(subscriptionPlan.id);
            if (!response.error) {
                notification.success({
                    message: 'Subscription Plan Deleted', 
                    description: `The subscription plan was deleted successfully.`
                });
                const list = [...subscriptionPlans];
                const ndx = list.findIndex((subscriptionPlan) => subscriptionPlan.id === response.id);
                list.splice(ndx, 1);
                setSubscriptionPlans(list);
            } else {
                notification.error({
                    message: `Deleting the Subscription Plan Failed!`, 
                    description: `A error occurred while deleting the subscription plan.`
                });
            }

            setDeleteOpen(false);
            
        } catch (e) {
            notification.error({
                message: `General Failure`, 
                description: `An general error occurred while an operation was ongoing.`
            });

            setDeleteOpen(false);
        }

        
    }

    return <div className="plans-container">
        <div className="header">
            <Search/>
            <div className="add-button">
                <Button 
                    type="primary" 
                    onClick={openAddSubscriptionPlan}>
                    Create Subscription Plan
                </Button>
            </div>
        </div>
        <SubscriptionPlanList 
            subscriptionPlans={subscriptionPlans}
            updateSubscriptionPlan={openUpdateSubscriptionPlan}
            deleteSubscriptionPlan={openDeleteSubscriptionPlan}/>
        
        <Modal
            title={mode === ADD_MODE ? 'Create Subscription Plan' : 'Update Subscription Plan'}
            open={subscriptionPlanOpen}
            onOk={() => form.submit()}
            onCancel={() => setSubscriptionPlanOpen(false)}
            okText={mode === ADD_MODE ? 'Create Subscription Plan' : 'Update Subscription lan'}
            centered={true}
            closable={false}
            width="36rem">
            <p>
                <Form 
                    form={form}
                    onFinish={saveSubscriptionPlan}>
                    <Form.Item name="id" style={{display: 'none'}}>
                        <Input type="hidden"/>
                    </Form.Item>
                    <label>Plan Name</label>
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Name is required."
                            }]}>
                            <Input />
                    </Form.Item>
                    <label>Frequency</label>
                    <Form.Item
                        name="frequency"
                        rules={[
                            {
                                required: true,
                                message: "Frequency is required."
                            }]}>
                            <Select options={frequencies} />
                    </Form.Item>
                    <label>Price</label>
                    <Form.Item
                        name="price"
                        rules={[
                            {
                                required: true,
                                message: "Price is required."
                            }]}>
                            <InputNumber />
                    </Form.Item>
                    <label>Credits</label>
                    <Form.Item
                        name="credits"
                        rules={[
                            {
                                required: true,
                                message: "Credits is required."
                            }]}>
                            <InputNumber />
                    </Form.Item>
                </Form>
                
            </p>
        </Modal>

        <Modal 
            title="Delete SubscriptionPlan" 
            open={deleteOpen} 
            onOk={confirmDeleteSubscriptionPlan} 
            onCancel={() => setDeleteOpen(false)}>
            <p>Are sure you want to delete this subscription plan?</p>
        </Modal>
    </div>
}