let AppConfig = {
    
    // HOST: '192.168.100.15',
    // RESOURCE_URL: 'http://192.168.100.15:8080',
    // KEYCLOAK_URL: 'http://192.168.100.15:8080/auth/realms/clubdjvideos/protocol/openid-connect/token',
    // CLIENT_SECRET: '8hIcvcvu1AmxO0SkRAdt1vEfMO0VF7Es',
    HOST: 'https://api.clubdjvideos.net',
    KEYCLOAK_URL: 'https://api.clubdjvideos.net/auth/realms/clubdjvideos/protocol/openid-connect/token',
    RESOURCE_URL: 'https://api.clubdjvideos.net',
    CLIENT_SECRET: 'idunqATPLVSGUPp3ea5a7u5SoaDnHExQ',
    VERSION: 'w0.0.2',
    isHTTPS: false,

    AWS_ACCESS_KEY: 'AKIAZAWYTZ7O5UPHCI7C',
    AWS_SECRET_KEY: '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',
    AWS_VIDEO_BUCKET: 'cdjv-videos',
    AWS_PREVIEW_BUCKET: 'cdjv-previews',
    AWS_CRATE_BUCKET: 'cdjv-crates',
    AWS_REGION: 'us-east-2',
}

if (process.env.NODE_ENV === 'production') {
    AppConfig.HOST = 'https://api.clubdjvideos.net';
    AppConfig.KEYCLOAK_URL = 'https://api.clubdjvideos.net/auth/realms/clubdjvideos/protocol/openid-connect/token';
    AppConfig.RESOURCE_URL = 'https://api.clubdjvideos.net';
    AppConfig.CLIENT_SECRET = 'idunqATPLVSGUPp3ea5a7u5SoaDnHExQ';
    AppConfig.isHTTPS = false;
}

export default AppConfig;